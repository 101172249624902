import * as React from "react"

function GiftedPointsBannerSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={63}
      height={57}
      viewBox="0 0 63 57"
      {...props}
    >
      <image
        data-name="Vector Smart Object"
        y={13}
        width={63}
        height={44}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAAsCAYAAADSHWDqAAAFEklEQVRogd2ae4gXVRTHP7/VstVKe2IlKT2osNostceuVmhJlD22iCQxqP4Ie1BkVgQRWVGEERREFEWlRFmhkfaystzYhB6IQW1LT2yLbcnNMlOrjbN9J8Zh5s7jzgzpFy78YM7v3vu953HPOTON1umXsJNhBDAVmAZMB44GtgFvAjcC3QHdoTsBb+NwkshO0+9dIjJDgJnAycCxwA/soOQb0mag2VOB3TP+d1/gSmABOxD5sSIaaHd/j7kmBz/+r+T3AU4XYRuHlji3mf0gjPxo4A5ggoLBQuCTEhfLguFAG3CGNHuczLsKHAyMAvqN/FLgRC1iJnEpsAy4C/iwog3YupNCfmuBaNeK1orDMcDqoSHiYZyn8bqs4gPPxYzYCQpOpwGtOYJUHvQC38m0XYfZEpBfDpydIDRDI+8h7KYrp01kTwGaKyC7CVgFvKWxDhiQadteD0j436DfG3nLcm4DrlWCEIfgEGyBR4FXgd9CciNkuqbZKSI+rAKy20QqIGu//4yRM+2/CFyTMI+ZPeEMz+7AecBcYI+UTdjpfqmTH6WTripArQ2RfS9y6C5cATye8Nz2vWdcemtkrtcYWQkdN74BVmq8DfxUcB4L3msczw935fZ1HUKfSK6Udr8qad7h0nAS2l1JTr+C3IMyoauAw0rY1O/AuyFTXis3KhtHpMzXkiXD61fiY2M8MBu4JcdG/4oJUlsrIBvgQO1xXopcS5GS1lzgZ6DJIbMBeEZl5KocQaoo7LY5H5ijpMm1twCfF8ntfwE+DefIEWxV4tSdOEM5aFKGaFq+0HFNJ2Fj0cLmfQf53oqJj5eGZ8vEi+KhLOYRh9WOZwcBe3lT3B5WfN2ggsusbr4H8R7gcmCRj+aT0FA6u7zg3AGaQ358ZkY/TsJmFXBPKw5ZEC5cz1v6uB4Yk/C8rSD5JqXIc+THaZmmC3Z9vqPAa6nur1FZn2ZGh+qCOLTmnOuokB8nHWhWfCYNL5KCElEV+ckqKV33ubWiZonwRC+6/wbZZ6Xlj7L+yYe8y++HqVkRlbFS91zgMvmxz/p/qOliWn4jobpzwmfxdfKjJL9cIMvoU5lrGr7YqimPNQdU2ZmGX1DOURg+5C1idkqDcbAG5I/SkG8jo0saXgx86znXf/C5PpDfu9DwIG4W87CyxSOBe3IQn6havku1iQW+FbI+e4ExCN/Wtcvvi2AL8LLM+jV1bvLAiD0AXBf5z0glX2cpWWq3g/Qlv0bmPySDrAsdIvy8NFUUccSjOF69g0m+5Dcp5SxyVXWLsN3HX3vuA/X604gHsL7EzWW8senIQd5K4edEurOEtcOYlVfeN+AhIn87nlui8xJwgVrJcysgbhiXU35sGeQ/1pvPaMOiU0RHK09fGsn4mrXhpN56XvTllN9QBnnDk8rJz5GGx6mye0RdnTDa1cPbKF/vkTs8BhzisYcVeeXr/DLDcv0n9C4wCZuV+i4pMH9Dhzolg6w1USeUpfksWJhCHLnC4oT3h2mw1PeiDIWN3VDmhl/URd7M+eqMsvZJyX0F1+lVL2F+TP/fSD+lNpglULV9nDAz5+usqWqFReNFFlgtcb/GGL2G26K8YrvKry7y++WUt4PauyD5MNa7Ghp1mX1PTvmB4IupKlEX+WU5i5RXFJErRV3kvwfuzChrpG+qeD+DqPOquxu4N0WmVx9BdNWxoTrJmx/fqm9zrCtj1mCwktje1N6upkVag6QcAP8AmyUPLY9eRjMAAAAASUVORK5CYII="
      />
      <image
        data-name="Vector Smart Object"
        x={10}
        width={45}
        height={19}
        xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAATCAYAAAAEaoRHAAACiUlEQVRIidXWX6jPdxzH8cfBKM5GDjai499WbGWy1TSNRjRTrDC1/FtqLkxDsautXOxmcqVQytqaIhnmQhIpcmGt1ObPcRAyIZrYbP6d9anXr36dC3bq/BzeN9/f+X0+n9/n+Xl/Xq/X99S9O2mO56Dex2vYUFC7PAfAnTAXEzAUKzsaujdewYlW3/fBwowfxyw8wE45RUfWIXzWav8G/Io3Av4evkA9fulo6J5oxvD83Q8bUUzWFd+Er4CeRWcMQ93Tlkd3zMu1lyvfjxkFBJvzvJBu78ES7MgBH2I7Dtcauvz+bLwdmB+wHtcDPwiNWIwP8CYGZF3p+mgsxTi8k3UXayGPYVWy+wlfRp+rcAN/Yjf2Rh4v4Bz+wlfYh7WYhqOJO5FJOXhLe3V6Sp4zsSibbsnGY6LbregR0LrMP5dnXWLtrUhoxeM2ayv0/PzwctzHGvSNBLpFf9swEQey5hbO5/OraErelrqEj3AQ/1Yi7UnVFuhino8TQQVqZLQ2Igf5LUbpj6k4lXUL8BL+STe3JA1KPfq/oNVVgS5dmhyIO/g2mxSYlXgZm2KcFozNujN5nsj8vrn++mh0HZYlZ3vmdlraCtm6imGGZIPVycrV6Whjwv9IoCdnrL7KaAVwcEDORrtNMdldfJ755cD32gNYOn0pRvgubi9RdBrTo8c/Av193F/q9cxpztiLcf5hXEt81aw65Z1+MVdYdHkTH+YgRZ+fRMelk79n/EDeWD8H+HZMeK2WsNWdluhpyH9S5X3/NcZn7H403isB35irvvc0AB8HXTr3Kf7GKFzFscRRj4xfydw7HQVbqYqhdsWQl6PbETFSiaMfq4Cfiap0+iQGBq5dHF6zwn+oh5M2p2jyZwAAAABJRU5ErkJggg=="
      />
    </svg>
  )
}

export default GiftedPointsBannerSVGR
