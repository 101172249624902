import CardSVGR from '../smart-assets/SVGRs/CardSVGR'
import BankSVGR from '../smart-assets/SVGRs/BankSVGR'
import SaleSVGR from '../smart-assets/SVGRs/SaleSVGR'

const config = {
    sections: [
        {
            id: 1,
            titleKey: 'card',
            SVGR: (props) => <CardSVGR {...props} />
        },
        {
            id: 2,
            titleKey: 'bank',
            SVGR: (props) => <BankSVGR {...props} />
        },
        {
            id: 3,
            titleKey: 'discount',
            SVGR: (props) => <SaleSVGR {...props} />
        },
    ]
}

export default config;